@import '../../styles/customMediaQueries.css';

.root {
  flex-grow: 1;
}

.contentContainer {
  composes: marketplaceModalRootStyles from global;
  min-height: calc(100vh - 60px);
  height: auto;
  padding: 0;
  
  @media (--viewportMedium) {
    height: unset;
    min-height: unset;
  }
  
  @media (--viewportSmall) {
    padding: 0 32px;
  }
}

.content {
  composes: forgotPassModalBaseStyles from global;
  padding: 0;
  
  
  @media (--viewportSmall) {
    border-radius: 16px;
    margin-top: 5vh;
    margin-bottom: 7.5vh;

    /* min-height: 700px; */
  }
}

/* ================ Tabs & Form ================ */

.tabs {
  @media (--viewportMedium) {
    margin-top: 6px;
  }
}

.tab {
  composes: marketplaceModalTitleStyles from global;
  padding: 0;
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    padding: 0;
    margin-top: 0;
  }
}

.form {
  margin-top: 24px;

  @media (--viewportMedium) {
    margin-top: 30px;
    padding-top: 2px;
  }
}

.loginForm {
  margin-top: 24px;

  /* We don't want the form to take the whole space so that on mobile view
  the social login buttons will be after the sign up button
  and not in the bottom of the page.
  We also need to add flex-basis value so that Safari will show the
  whole form correctly.  */
  flex-grow: 0;
  flex-basis: 260px;

  @media (--viewportMedium) {
    flex-basis: 330px;
    margin-top: 30px;
    padding-top: 2px;
    padding-left: 32px;
    padding-right: 32px;
    padding-bottom: 32px;
  }
}

.signupForm {
  margin-top: 24px;

  /* We don't want the form to take the whole space so that on mobile view
  the social login buttons will be after the sign up button
  and not in the bottom of the page.
  We also need to add flex-basis value so that Safari will show the
  whole form correctly.  */
  flex-grow: 0;
  flex-basis: 340px;

  @media (--viewportMedium) {
    flex-basis: 410px;
    margin-top: 30px;
    padding-top: 2px;
  }
}

.bottomWrapper {
  composes: marketplaceModalBottomWrapper from global;
  padding: 0 32px 32px 32px;

  /* Align the helper links to the left since there isn't a Primary Button anymore */
  text-align: left;
}

/* Terms of Service modal*/

.privacyWrapper,
.termsWrapper {
  width: 100%;
  padding-top: 40px;

  @media (--viewportMedium) {
    width: 604px;
    padding-top: 11px;
  }
}

/* userType selection */
.userTypeSelect {
  margin-bottom: 24px;

  @media (--viewportMedium) {
    margin-bottom: 32px;
  }
}

.selectedLabel {
  composes: label from global;
}
.selectedValue {
  margin-top: 0;
  margin-bottom: 6px;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
    padding: 2px 0 6px 0;
  }
}

/* ================ Hide Top bar in screens smaller than 768px  ================ */

.hideOnMobile {
  display: none;

  @media (--viewportMedium) {
    display: block;
  }
}

/* ================ Close icon ================ */

.verifyCloseOTP {
  padding: 23px 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: var(--authBorder);
}

.verifyClose {
  composes: marketplaceModalCloseStyles from global;

  /* This is not a real modal, z-index should not be modal's z-index */
  z-index: 1;
}

.closeIcon {
  width: 10%;
}

.closeText {
  composes: marketplaceModalCloseText from global;
}

.closeTextOTP {
  text-align: center;
  width: 100%;
  font-weight: var(--fontWeightBold);
  font-size: var(--fontSizeRegular);
  line-height: 18px;
  color: var(--colorPrimary);
}

.closeIcon {
  composes: marketplaceModalCloseIcon from global;
}

/* ================ Typography ================ */

/* Title of the modal */
.modalTitle {
  composes: forgotPassTitleStyles from global;
  font-weight: var(--fontWeightMedium);
  color: var(--colorPrimary);
  padding: 32px;
  line-height: 26px;
}

.agreementTitle {
  composes: agreementTitleStyles from global;
}

/* Paragraph for the Modal */
.modalMessage {
  composes: marketplaceModalParagraphStyles from global;
}

.agreementSubheading,
.agreementSubheadingStrong {
  composes: agreementParagraphStyles from global;
}

.agreementSubheadingStrong {
  font-weight: var(--fontWeightBold);
}

/* Make the email pop */
.email {
  font-weight: var(--fontWeightHighlightEmail);

  /* fallback option */
  word-break: break-all;
  /* use break-word if available */
  word-break: break-word;
  hyphens: auto;
}

/* Helper links */
.modalHelperLink {
  composes: a from global;
  composes: confirmEmailHelperLink from global;
  color: var(--colorSecondary);
  text-decoration: underline;
}

/* Helper texts for the links, not the actual links */
.modalHelperText {
  composes: confirmEmailHelperText from global;
}

.modalIcon {
  composes: marketplaceModalIconStyles from global;

  /* Align the icon and rest of the content in the modal. Sign & Login doesn't use an icon, maybe we should add one? */
  margin-top: 47px;

  @media (--viewportMedium) {
    margin-top: 0;
  }
}

/* ================ Errors ================ */

.error {
  composes: marketplaceModalPasswordMargins from global;
  padding: 0;
  color: var(--colorFail);
  
  @media (--viewportSmall) {
    padding: 0 24px;
  }
}

/* ================ Social logins & SSO ================ */

.signupWithIdpTitle {
  composes: marketplaceModalTitleStyles from global;
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 16px;
  color: var(--colorBlack);

  @media (--viewportMedium) {
    padding: 0;
    margin-top: 6px;
  }
}

.confirmInfoText {
}

.buttonIcon {
  position: absolute;
  left: 0;
  margin-left: 20px;
}

.socialButtonWrapper {
  margin-bottom: 6px;
  @media (--viewportMedium) {
    margin-top: 8px;
  }
}

.socialButtonsOr {
  width: 100%;
  height: 32px;
  margin: 28px 0 20px 0;
  text-align: center;
  position: relative;
  background-color: var(--colorWhite);

  &:after {
    content: '';
    width: 100%;
    border-bottom: solid 1px #d2d2d2;
    position: absolute;
    left: 0;
    top: 50%;
    z-index: 1;
  }
}

@media (--viewportMedium) {
  .socialButtonsOr {
    height: 34px;
    margin: 15px 0;
  }
}

.socialButtonsOrText {
  background-color: var(--colorWhite);
  width: auto;
  display: inline-block;
  z-index: 3;
  padding: 0 20px 0 20px;
  position: relative;
  margin: 0;
}

.scrollLayer {
  width: 100% !important;
}

.inputContainer {
  padding: 0 32px;
}

.customInput {
  width: 100%;
  max-width: 192px;
  font-size: var(--textPriceRegular);
  color: var(--colorBlack);
  padding: 10px;
  border: 1px solid var(--colorNeutralGray);
  box-sizing: border-box;
}

.customInput::placeholder {
  font-size: var(--fontSizeLarge);
}

.continueButton {
  margin-top: 32px;
}

.closeIcon {
  display: none;
}

.container {
  background: var(--colorWhite);
  border: var(--authBorder);
  box-shadow: var(--blockShadow);
  border-radius: 16px;
  padding: 32px;
}

.modalDecline {
  font-weight: var(--fontWeightSemiBold);
  font-size: var(--fontSizeRegular);
  line-height: 18px;
  color: var(--colorSecondary);
  border: none;
}

.modalDecline:hover {
  box-shadow: none;
}

.modalButton {
  margin-top: 48px;
}

.subheadiangStrong {
  font-weight: bold;
}

.closeIcon {
  display: none;
}

.authTopbarContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px 24px;
  border-bottom: 1px solid var(--colorBorderSecondary);
  
  @media (--viewportSmall) {
    padding: 15px 32px;
  }
}

.authTopbar {
  width: 100%;
  max-width: 1128px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.avatar {
  background-image: url('/src/assets/Avatar.png');
}

.profileMenuLabel {
  composes: profileMenuLabel from global;
}

.profileMenuContent {
  min-width: 276px;
  padding-top: 20px;
  padding-bottom: 20px;
}

/* left animated "border" like hover element */
.menuItemBorder {
  position: absolute;
  top: 2px;
  left: 0px;
  height: calc(100% - 4px);
  width: 0;
  transition: width var(--transitionStyleButton);
}

.menuLink,
.topBorder {
  composes: textSmall from global;
  position: relative;
  display: block;

  /* Dimensions */
  width: 100%;
  min-width: 276px;
  margin: 0;
  padding: 10px 24px;

  /* Layout details */
  color: var(--colorPrimary);
  text-align: left;
  transition: var(--transitionStyleButton);

  &:hover {
    color: var(--colorSecondary);
    text-decoration: none;

    & .menuItemBorder {
      width: 6px;
      background-color: var(--marketplaceColor);
    }
  }

  @media (--viewportMedium) {
    margin: 0;
  }
}

.menuLink:hover {
  color: var(--colorSecondary);
}

.menuItem::marker {
  color: transparent !important;
}

.topBorder {
  border-top: 1px solid #F2F2F7;
  margin-top: 10px;
  padding: 10px 24px;
}
