@import '../../../styles/customMediaQueries.css';

.root {
  composes: marketplaceModalFormRootStyles from global;

  justify-content: flex-start;
  padding: 0;
  
  @media (--viewportMedium) {
    padding-bottom: 32px;
    justify-content: space-between;
  }
  
  @media (--viewportSmall) {
    padding: 0 24px 24px;
  }
}

.password {
  composes: marketplaceModalPasswordMargins from global;
}

.bottomWrapper {
  composes: marketplaceModalBottomWrapper from global;
  position: relative;
}

/* Adjust the bottomWrapper's margin to match modals without the helper text */
.bottomWrapperText {
  composes: marketplaceModalBottomWrapperText from global;
}

.recoveryLink {
  composes: forgotPasswordHelperLink from global;
}

.recoveryLinkInfo {
  composes: marketplaceModalHelperText from global;
}

.welcomContainer {
  padding: 0;
  
  @media (--viewportSmall) {
    padding: 0 32px;
  }
}

.welcomContent {
  text-align: left;
  padding-bottom: 36px;
  padding-top: 36px;
  border-bottom: none;
  
  @media (--viewportSmall) {
    text-align: center;
    border-bottom: var(--borderBottom);
  }
}

.welcomeText,
.subTitle {
  padding: 0;
  margin-top: 0;
  margin-bottom: 0;
  color: var(--colorPrimary);
}

.welcomeText {
  font-weight: var(--fontWeightBold);
  font-size: var(--fontSizeLarge);
  line-height: 100%;
  margin-bottom: 24px !important;
}

.subTitle {
  font-weight: var(--fontWeightMedium);
  font-size: var(--textPriceRegular);
  line-height: 19px;
}

.signupText {
  color: var(--colorSecondary);
}

.label {
  background-color: aqua;
}

.passwordContainer {
  position: relative;
}

.togglePassword {
  position: absolute;
  right: 15px;
  top: 45px;
  transform: translateY(-50%);
  background: none;
  border: none;
  cursor: pointer;
  outline: none;
  
  @media (--viewportMedium) {
    top: 55px;
  }
  /*
  @media (max-width: 767px) and (min-width: 550px) {
    top: 47%;
    transform: translateY(-53%);
  } */
}

.emailRoot {
  position: relative;
}
