@import '../../../styles/customMediaQueries.css';

.root {
  composes: marketplaceModalFormRootStyles from global;

  -webkit-box-pack: start;

      -ms-flex-pack: start;

          justify-content: flex-start;
  margin: 0;

  @media (--viewportMedium) {
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
  }
}

.defaultUserFields {
  padding: 0;
  
  @media (--viewportSmall) {
    padding: 0 32px;
  }
}

.name {
  position:relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin-top: 24px;
  margin-bottom: 32px;

  @media (--viewportMedium) {
  }
}

.row,
.customFields {
  margin-top: 24px;

  @media (--viewportMedium) {
    margin-top: 32px;
  }
}

.firstNameRoot {
  width: 100%;
  /* width: calc(34% - 9px); */
}

.firstNameRoot input {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom: none;
}

input {
  padding: 8px 12px;
}

.lastNameRoot input {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.lastNameRoot {
  width: 100%;
  /* width: calc(66% - 9px); */
}

.password {
  composes: marketplaceModalPasswordMargins from global;
}

.bottomWrapper {
  composes: marketplaceModalBottomWrapper from global;
  padding: 0;
  padding-bottom: 32px;
  margin-top: 45px;
  position: relative;
  
  @media (--viewportSmall) {
    padding: 0 32px 32px;
  }
}

.birthdate {
  position: relative;
  margin-top: 32px;
}


.titleContainer {
  width: 100%;
  padding: 24px 0;
  
  @media (--viewportSmall) {
    padding: 20px 0;
    text-align: center;
    border-bottom: var(--authBorder);
  }
}

.signUpTitle {
  font-weight: var(--fontWeightBold);
  font-size: var(--fontSizeRegular);
  line-height: 18px;
  color: var(--colorPrimary);
  margin: 0;
}

.passwordContainer {
  position: relative;
}

.togglePassword {
  position: absolute;
  right: 10px;
  top: 45px;
  -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
          transform: translateY(-50%);
  background: none;
  border: none;
  cursor: pointer;
  outline: none;
  
  @media (--viewportMedium) {
    top: 54px;
  }
}

.signupButton:hover {
  background-color: var(--colorSecondary);
}

.errorBorder {
  border-bottom: 2px solid red; /* Adjust color and thickness as needed */
}

.emailRoot {
  position: relative;
}
